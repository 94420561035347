<template>
  <main class="models-page">
    <section>
      <div class="container py-5">
        <div class="row">
          <div class="md-down:col-12 lg:col-5 xl:col-3">
            <mega-card :title="$t('model_title')">
              <div class="card-body">
                <mega-input :label="$t('model_name')" v-model="model.name" />
                <mega-select
                  class="mb-0"
                  :label="$t('model_group')"
                  :placeholder="$t('model_group_holder')"
                  :options="[]"
                  v-if="!model.idc_round_type"
                />
                <group-select
                  class="mb-0"
                  :label="$t('model_group')"
                  :placeholder="$t('model_group_holder')"
                  :type="model.idc_round_type"
                  v-if="model.idc_round_type"
                  v-model="model.idt_group"
                />
              </div>

              <ul class="menu-list">
                <li class="menu-item">
                  <mega-switch
                    class="w-100 mb-0 bg-success"
                    :label="$t('model_show')"
                    @click="toggleActive"
                    :checked="model['is_active']"
                  />
                </li>
              </ul>
            </mega-card>

            <mega-card
              :title="$t('model_modifications')"
              :menu="[
                { text: model.modifications ? model.modifications.length : 0 }
              ]"
            >
              <div class="card-body">
                <div class="row">
                  <div
                    class="xs:mb-3 xs:col-12 sm-up:col-9 md:col-10 lg-up:col-8"
                  >
                    <mega-select
                      v-model="current_modif"
                      :options="modif_list"
                      @select="openModif"
                    />
                  </div>
                  <div class="col sm-up:pl-0">
                    <mega-button
                      class="btn w-100 btn-primary p-0"
                      :loading="loading"
                      @click="addModification"
                      ><i class="i-plus"></i
                    ></mega-button>
                  </div>
                </div>
              </div>

              <div class="card-body pt-0">
                <mega-drop-zone
                  @change="renderFile"
                  :type="['image/jpeg', 'image/png', 'image/gif']"
                >
                  <mega-image ratio="1x1" :src="preview" />
                </mega-drop-zone>
              </div>

              <div class="card-body">
                <mega-switch
                  class="w-100 bg-success"
                  :label="$t('model_mod_show')"
                  :disabled="!(mask['ios'] || mask['android'] || mask['web'])"
                  @click="changeModif('is_active', !modification['is_active'])"
                  :checked="modification['is_active']"
                />

                <mega-input
                  :label="$t('model_mod_name')"
                  v-model="modification.name"
                />
              </div>
            </mega-card>

            <mega-card>
              <div class="card-body">
                <mega-button
                  class="btn w-100 btn-success"
                  :loading="loading"
                  @click="submit"
                  >{{ $t("model_save") }}</mega-button
                >
              </div>
            </mega-card>

            <mega-card>
              <div class="card-body" style="overflow: hidden;">
                <mega-image ratio="1x1" :src="qr_image" save-as-img />
              </div>
            </mega-card>
          </div>

          <div class="md-down:col-12 lg:col-7 xl:col-9">
            <mega-card
              class="mb-0"
              v-if="loaded && !(mask['android'] && mask['android']['sfb'])"
            >
              <mega-alert
                class="mb-0"
                :title="$t('model_warn')"
                type="warning"
                >{{ $t("model_android_sfb_mask_warn") }}</mega-alert
              >
            </mega-card>

            <mega-card
              class="mb-0"
              v-if="loaded && !(mask['android'] && mask['android']['texture'])"
            >
              <mega-alert
                class="mb-0"
                :title="$t('model_warn')"
                type="warning"
                >{{ $t("model_android_texture_mask_warn") }}</mega-alert
              >
            </mega-card>

            <mega-card class="mb-0" v-if="loaded && !mask['ios']">
              <mega-alert
                class="mb-0"
                :title="$t('model_warn')"
                type="warning"
                >{{ $t("model_ios_mask_warn") }}</mega-alert
              >
            </mega-card>

            <mega-card class="mb-0" v-if="loaded && !mask['web']">
              <mega-alert
                class="mb-0"
                :title="$t('model_warn')"
                type="warning"
                >{{ $t("model_web_mask_warn") }}</mega-alert
              >
            </mega-card>

            <mega-card :title="$t('model_vis')">
              <div class="card-body">
                <ul class="menu-list">
                  <li class="menu-item">
                    <mega-switch
                      class="w-100 mb-0 bg-success"
                      :label="$t('model_vis_ios')"
                      :disabled="is_visible_ios"
                      @click="changeModif('is_ios', !modification['is_ios'])"
                      :checked="modification['is_ios']"
                    />
                  </li>
                  <li class="menu-item" v-if="mask">
                    <mega-switch
                      class="w-100 mb-0 bg-success"
                      :label="$t('model_vis_android')"
                      :disabled="is_visible_android"
                      @click="
                        changeModif('is_android', !modification['is_android'])
                      "
                      :checked="modification['is_android']"
                    />
                  </li>
                  <li class="menu-item">
                    <mega-switch
                      class="w-100 mb-0 bg-success"
                      :label="$t('model_vis_web')"
                      :disabled="is_visible_web"
                      @click="changeModif('is_web', !modification['is_web'])"
                      :checked="modification['is_web']"
                    />
                  </li>
                </ul>
              </div>
            </mega-card>

            <mega-card :title="$t('model_archives')">
              <div class="card-body">
                <ul class="menu-list">
                  <component
                    :is="modelFileComponent"
                    v-if="modification"
                    type="apple"
                    placeholder="iOS"
                    :mask="mask"
                    :modif="modification"
                    @drop="getModel"
                  />
                  <component
                    :is="modelFileComponent"
                    v-if="modification"
                    type="android_sfb"
                    placeholder="Android SFB"
                    :mask="mask"
                    :modif="modification"
                    @drop="getModel"
                  />
                  <component
                    :is="modelFileComponent"
                    v-if="modification"
                    type="android_tex"
                    placeholder="Android Texture"
                    :mask="mask"
                    :modif="modification"
                    @drop="getModel"
                  />
                  <component
                    :is="modelFileComponent"
                    v-if="modification"
                    type="web"
                    placeholder="Web"
                    :mask="mask"
                    :modif="modification"
                    @drop="getModel"
                  />
                </ul>
              </div>
            </mega-card>

            <mega-card
              :title="$t('model_rec_imgs')"
              :menu="[{ icon: 'i-plus', click: addImage }]"
            >
              <div class="card-body" v-if="serverRefImages.length">
                <div class="form-label">{{ $t("model_rec_server") }}</div>

                <ul class="menu-list border-left">
                  <li
                    class="menu-item cursor-pointer"
                    v-for="(img, i) in serverRefImages"
                    :key="i"
                    @click="editImage(img['idt_ref_image'])"
                  >
                    <div class="d-flex flex-column">
                      <span class="form-label"
                        >ID: {{ img.idt_ref_image }}</span
                      >
                      <span class="mr-auto form-label">{{ img.name }}</span>
                    </div>

                    <div style="width: 40px;">
                      <mega-image ratio="1x1" small :src="img.url" />
                    </div>
                  </li>
                </ul>
              </div>

              <div class="card-body" v-if="ref_images.length">
                <div class="form-label">{{ $t("model_rec_old") }}</div>

                <ul class="menu-list border-left">
                  <li
                    class="menu-item cursor-pointer"
                    v-for="(img, i) in ref_images"
                    :key="i"
                    @click="editImage(img['idt_ref_image'])"
                  >
                    <div class="d-flex flex-column">
                      <span class="form-label"
                        >ID: {{ img.idt_ref_image }}</span
                      >
                      <span class="mr-auto form-label">{{ img.name }}</span>
                    </div>

                    <div style="width: 40px;">
                      <mega-image ratio="1x1" small :src="img.url" />
                    </div>
                  </li>
                </ul>
              </div>
            </mega-card>
          </div>
        </div>
      </div>
    </section>

    <new-image
      ref="new-image"
      :id="model_id"
      :mod_id="modification_id"
      @close="getModel"
    />
    <edit-image ref="edit-image" @close="getModel" />
  </main>
</template>

<script>
import GroupSelect from "../../components/group-select";

import ModelFile from "./components/model-file";
import QrCodeWithLogo from "qr-code-with-logo";

import NewImage from "../../components/new_image";
import EditImage from "../../components/edit_image";

export default {
  name: "MaskPage",
  props: ["model_id", "modification_id"],
  data() {
    return {
      model: {},
      modification: {},
      mask: {},
      ref_images: [],
      serverRefImages: [],
      preview: undefined,
      loading: true,
      rendering: false,
      loaded: false,
      current_modif: undefined,
      modelFileComponent: null,
      qr_image: undefined,
      qr_code: undefined
    };
  },
  watch: {
    $route() {
      this.getModel();
    }
  },
  computed: {
    modif_list() {
      let arr = [];
      let modifications = this.model.modifications || [];

      modifications.map((item, i) => {
        arr.push({ value: i, name: item.name });
      });

      return arr;
    },
    is_visible_ios() {
      return !(this.mask && this.mask.ios);
    },
    is_visible_android() {
      return !(
        this.mask["android"] &&
        (this.mask["android"]["sfb"] || this.mask["android"]["texture"])
      );
    },
    is_visible_web() {
      return !(this.mask && this.mask.web);
    }
  },
  mounted() {
    this.$navbar.name = "Mask";

    this.buildQrCanvas();
    this.getModel();
  },
  methods: {
    buildQrCanvas() {
      this.qr_code = document.createElement("canvas");
      this.qr_code.width = 1000;
      this.qr_code.height = 1000;
      this.qr_code.style.top = "-9999999px";
      this.qr_code.style.left = "-9999999px";
      this.qr_code.style.position = "absolute";
      this.qr_code.style.pointerEvents = "none";
      this.qr_code.style.visibility = "hidden";
      this.qr_code.style.imageRendering = "pixelated";
      this.qr_code.style.imageRendering = "crisp-edges";

      document.body.appendChild(this.qr_code);
    },

    addImage() {
      if (window && window.isMobileOrTablet())
        this.$router.push(
          `/ref_image/new/${this.model_id}/${this.modification_id}`
        );
      else this.$refs["new-image"].open();
    },

    editImage(id) {
      if (window && window.isMobileOrTablet())
        this.$router.push("/ref_image/" + id);
      else this.$refs["edit-image"].open(id);
    },

    toggleActive() {
      let active = !this.model["is_active"];

      let data = {
        idt_model: this.model["idt_model"],
        is_active: active
      };

      this.$api.v2
        .put("/model", data)
        .then(response => {
          this.model = response.data;
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    },

    change(prop, value) {
      let data = {
        idt_model: this.model["idt_model"]
      };

      data[prop] = value;

      this.$api.v2
        .put("/model", data)
        .then(response => {
          this.model[prop] = response.data[prop];
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    },

    changeModif(prop, value) {
      let formData = new FormData();
      formData.append("idt_model_modif", this.modification["idt_model_modif"]);
      formData.append(prop, value);

      this.$api.v2
        .put("/modif", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          this.modification = response.data["modif"];

          this.getModel();
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    },

    openModif(i) {
      let modif = this.model.modifications[i] || [];

      if (modif.ref_images) {
        modif["ref_images"].map(ref => {
          if (!ref["server_side"]) this.ref_images.push(ref);
          else this.serverRefImages.push(ref);
        });
      }

      if (modif) {
        this.current_modif = i;
        if (this.modification_id !== modif["idt_model_modif"])
          this.$router.push({
            params: { modification_id: modif["idt_model_modif"] }
          });

        this.$navbar.name = `${this.$t("model_modification")} #${
          modif["idt_model_modif"]
        } | ${this.model.name}`;

        if (modif["url_icon"]) {
          this.$render(modif["url_icon"]).then(src => {
            this.preview = src;
          });
        } else this.preview = undefined;

        let nodeQrCodeOptions = {
            margin: 0,
            color: {
              dark: "#5cc8c8",
              light: "#0000"
            }
          },
          logo = {
            src: require("../../../assets/logo-sphere.svg"),
            borderRadius: 0,
            logoSize: 0.2,
            radius: 0
          };

        QrCodeWithLogo.toCanvas({
          canvas: this.qr_code,
          content: "https://arround.world/?qr=" + modif["idt_model_modif"],
          width: 500,
          nodeQrCodeOptions,
          logo
        }).then(() => {
          this.qr_image = this.qr_code.toDataURL();

          this.qr_code.removeAttribute("style");
          this.qr_code.width = 1000;
          this.qr_code.height = 1000;
          this.qr_code.style.top = "-9999999px";
          this.qr_code.style.left = "-9999999px";
          this.qr_code.style.position = "absolute";
          this.qr_code.style.pointerEvents = "none";
          this.qr_code.style.visibility = "hidden";
          this.qr_code.style.imageRendering = "pixelated";
          this.qr_code.style.imageRendering = "crisp-edges";
        });

        this.modification = modif;

        this.setMask(modif.mask);

        this.modelFileComponent = () => import("./components/model-file");
      }
    },

    renderFile(file) {
      this.loading = true;
      this.rendering = true;

      let formData = new FormData();
      formData.append("idt_model_modif", this.modification["idt_model_modif"]);
      formData.append("image", file);

      this.$api.v2
        .put("/modif", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          this.getModel();
          this.loading = false;
          this.$alert.success("Preview was updated");
          let img = new Image();
          img.src =
            response.data["modif"]["url_icon"] + "?t=" + new Date().getTime();

          setTimeout(() => {
            this.preview = img.src;
          }, 100);

          img.onload = () => {
            setTimeout(() => {
              this.rendering = false;
            }, 200);
          };
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    },

    getModel() {
      this.$api.v2
        .get("/model", { params: { idt_model: this.model_id } })
        .then(response => {
          this.model = response.data;

          if (
            this.model.modifications.filter(
              modif => modif.idt_model_modif === Number(this.modification_id)
            ).length === 0
          ) {
            throw "This modification do not exist!";
          }

          this.loaded = true;
          this.loading = false;

          this.ref_images = [];
          this.serverRefImages = [];

          let id = this.modification_id || undefined;

          if (id) {
            let modifications = this.model.modifications || [];

            modifications.map((item, i) => {
              if (item["idt_model_modif"] === parseInt(id)) this.openModif(i);
            });
          } else this.openModif(0);
        })
        .catch(err => {
          this.$router.replace("/404");
        });
    },

    submit() {
      this.loading = true;

      let data = {
        idt_model: this.model.idt_model,
        idt_group: this.model.idt_group,
        name: this.model.name
      };

      this.$api.v2
        .put("/model", data)
        .then(() => {
          let formData = new FormData();
          Object.keys(this.modification).map(prop => {
            if (
              this.modification[prop] !== undefined &&
              typeof this.modification[prop] !== "object"
            )
              formData.append(prop, this.modification[prop]);
          });

          formData.append("mask", this.mask);

          this.$api.v2
            .put("/modif", formData, {
              headers: { "Content-Type": "multipart/form-data" }
            })
            .then(() => {
              this.loading = false;
              this.$alert.success("Modification was updated");
            })
            .catch(() => {
              this.$alert.danger("Oops.. Server error");
            });
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    },

    addModification() {
      this.loading = true;

      let formData = new FormData();
      formData.append("idt_model", this.model_id);
      formData.append("name", "New Modification");

      this.$api.v2
        .post("/modif", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => response.data["modif"])
        .then(modification => {
          this.loading = false;
          this.getModel();
        });
    },

    setMask(mask) {
      this.$set(this.mask, "ios", null);
      this.$set(this.mask, "web", null);
      this.$set(this.mask, "android", {});
      this.$set(this.mask["android"], "sfb", null);
      this.$set(this.mask["android"], "texture", null);

      if (mask) {
        this.$set(this.mask, "ios", mask.ios || null);
        this.$set(this.mask, "web", mask.web || null);
        this.$set(this.mask, "android", mask.android || null);
        if (this.mask["android"]) {
          this.$set(this.mask["android"], "sfb", mask.android.sfb || null);
          this.$set(
            this.mask["android"],
            "texture",
            mask.android.texture || null
          );
        }
      }
    }
  },
  components: {
    GroupSelect,
    ModelFile,
    EditImage,
    NewImage
  }
};
</script>
