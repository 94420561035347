var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "models-page" },
    [
      _c("section", [
        _c("div", { staticClass: "container py-5" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "md-down:col-12 lg:col-5 xl:col-3" },
              [
                _c("mega-card", { attrs: { title: _vm.$t("model_title") } }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("mega-input", {
                        attrs: { label: _vm.$t("model_name") },
                        model: {
                          value: _vm.model.name,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "name", $$v)
                          },
                          expression: "model.name"
                        }
                      }),
                      _vm._v(" "),
                      !_vm.model.idc_round_type
                        ? _c("mega-select", {
                            staticClass: "mb-0",
                            attrs: {
                              label: _vm.$t("model_group"),
                              placeholder: _vm.$t("model_group_holder"),
                              options: []
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.model.idc_round_type
                        ? _c("group-select", {
                            staticClass: "mb-0",
                            attrs: {
                              label: _vm.$t("model_group"),
                              placeholder: _vm.$t("model_group_holder"),
                              type: _vm.model.idc_round_type
                            },
                            model: {
                              value: _vm.model.idt_group,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "idt_group", $$v)
                              },
                              expression: "model.idt_group"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("ul", { staticClass: "menu-list" }, [
                    _c(
                      "li",
                      { staticClass: "menu-item" },
                      [
                        _c("mega-switch", {
                          staticClass: "w-100 mb-0 bg-success",
                          attrs: {
                            label: _vm.$t("model_show"),
                            checked: _vm.model["is_active"]
                          },
                          on: { click: _vm.toggleActive }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "mega-card",
                  {
                    attrs: {
                      title: _vm.$t("model_modifications"),
                      menu: [
                        {
                          text: _vm.model.modifications
                            ? _vm.model.modifications.length
                            : 0
                        }
                      ]
                    }
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "xs:mb-3 xs:col-12 sm-up:col-9 md:col-10 lg-up:col-8"
                          },
                          [
                            _c("mega-select", {
                              attrs: { options: _vm.modif_list },
                              on: { select: _vm.openModif },
                              model: {
                                value: _vm.current_modif,
                                callback: function($$v) {
                                  _vm.current_modif = $$v
                                },
                                expression: "current_modif"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col sm-up:pl-0" },
                          [
                            _c(
                              "mega-button",
                              {
                                staticClass: "btn w-100 btn-primary p-0",
                                attrs: { loading: _vm.loading },
                                on: { click: _vm.addModification }
                              },
                              [_c("i", { staticClass: "i-plus" })]
                            )
                          ],
                          1
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body pt-0" },
                      [
                        _c(
                          "mega-drop-zone",
                          {
                            attrs: {
                              type: ["image/jpeg", "image/png", "image/gif"]
                            },
                            on: { change: _vm.renderFile }
                          },
                          [
                            _c("mega-image", {
                              attrs: { ratio: "1x1", src: _vm.preview }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _c("mega-switch", {
                          staticClass: "w-100 bg-success",
                          attrs: {
                            label: _vm.$t("model_mod_show"),
                            disabled: !(
                              _vm.mask["ios"] ||
                              _vm.mask["android"] ||
                              _vm.mask["web"]
                            ),
                            checked: _vm.modification["is_active"]
                          },
                          on: {
                            click: function($event) {
                              return _vm.changeModif(
                                "is_active",
                                !_vm.modification["is_active"]
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("mega-input", {
                          attrs: { label: _vm.$t("model_mod_name") },
                          model: {
                            value: _vm.modification.name,
                            callback: function($$v) {
                              _vm.$set(_vm.modification, "name", $$v)
                            },
                            expression: "modification.name"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c("mega-card", [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c(
                        "mega-button",
                        {
                          staticClass: "btn w-100 btn-success",
                          attrs: { loading: _vm.loading },
                          on: { click: _vm.submit }
                        },
                        [_vm._v(_vm._s(_vm.$t("model_save")))]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("mega-card", [
                  _c(
                    "div",
                    {
                      staticClass: "card-body",
                      staticStyle: { overflow: "hidden" }
                    },
                    [
                      _c("mega-image", {
                        attrs: {
                          ratio: "1x1",
                          src: _vm.qr_image,
                          "save-as-img": ""
                        }
                      })
                    ],
                    1
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "md-down:col-12 lg:col-7 xl:col-9" },
              [
                _vm.loaded &&
                !(_vm.mask["android"] && _vm.mask["android"]["sfb"])
                  ? _c(
                      "mega-card",
                      { staticClass: "mb-0" },
                      [
                        _c(
                          "mega-alert",
                          {
                            staticClass: "mb-0",
                            attrs: {
                              title: _vm.$t("model_warn"),
                              type: "warning"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("model_android_sfb_mask_warn"))
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loaded &&
                !(_vm.mask["android"] && _vm.mask["android"]["texture"])
                  ? _c(
                      "mega-card",
                      { staticClass: "mb-0" },
                      [
                        _c(
                          "mega-alert",
                          {
                            staticClass: "mb-0",
                            attrs: {
                              title: _vm.$t("model_warn"),
                              type: "warning"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("model_android_texture_mask_warn"))
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loaded && !_vm.mask["ios"]
                  ? _c(
                      "mega-card",
                      { staticClass: "mb-0" },
                      [
                        _c(
                          "mega-alert",
                          {
                            staticClass: "mb-0",
                            attrs: {
                              title: _vm.$t("model_warn"),
                              type: "warning"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("model_ios_mask_warn")))]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loaded && !_vm.mask["web"]
                  ? _c(
                      "mega-card",
                      { staticClass: "mb-0" },
                      [
                        _c(
                          "mega-alert",
                          {
                            staticClass: "mb-0",
                            attrs: {
                              title: _vm.$t("model_warn"),
                              type: "warning"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("model_web_mask_warn")))]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("mega-card", { attrs: { title: _vm.$t("model_vis") } }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("ul", { staticClass: "menu-list" }, [
                      _c(
                        "li",
                        { staticClass: "menu-item" },
                        [
                          _c("mega-switch", {
                            staticClass: "w-100 mb-0 bg-success",
                            attrs: {
                              label: _vm.$t("model_vis_ios"),
                              disabled: _vm.is_visible_ios,
                              checked: _vm.modification["is_ios"]
                            },
                            on: {
                              click: function($event) {
                                return _vm.changeModif(
                                  "is_ios",
                                  !_vm.modification["is_ios"]
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.mask
                        ? _c(
                            "li",
                            { staticClass: "menu-item" },
                            [
                              _c("mega-switch", {
                                staticClass: "w-100 mb-0 bg-success",
                                attrs: {
                                  label: _vm.$t("model_vis_android"),
                                  disabled: _vm.is_visible_android,
                                  checked: _vm.modification["is_android"]
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.changeModif(
                                      "is_android",
                                      !_vm.modification["is_android"]
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "menu-item" },
                        [
                          _c("mega-switch", {
                            staticClass: "w-100 mb-0 bg-success",
                            attrs: {
                              label: _vm.$t("model_vis_web"),
                              disabled: _vm.is_visible_web,
                              checked: _vm.modification["is_web"]
                            },
                            on: {
                              click: function($event) {
                                return _vm.changeModif(
                                  "is_web",
                                  !_vm.modification["is_web"]
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "mega-card",
                  { attrs: { title: _vm.$t("model_archives") } },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c(
                        "ul",
                        { staticClass: "menu-list" },
                        [
                          _vm.modification
                            ? _c(_vm.modelFileComponent, {
                                tag: "component",
                                attrs: {
                                  type: "apple",
                                  placeholder: "iOS",
                                  mask: _vm.mask,
                                  modif: _vm.modification
                                },
                                on: { drop: _vm.getModel }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.modification
                            ? _c(_vm.modelFileComponent, {
                                tag: "component",
                                attrs: {
                                  type: "android_sfb",
                                  placeholder: "Android SFB",
                                  mask: _vm.mask,
                                  modif: _vm.modification
                                },
                                on: { drop: _vm.getModel }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.modification
                            ? _c(_vm.modelFileComponent, {
                                tag: "component",
                                attrs: {
                                  type: "android_tex",
                                  placeholder: "Android Texture",
                                  mask: _vm.mask,
                                  modif: _vm.modification
                                },
                                on: { drop: _vm.getModel }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.modification
                            ? _c(_vm.modelFileComponent, {
                                tag: "component",
                                attrs: {
                                  type: "web",
                                  placeholder: "Web",
                                  mask: _vm.mask,
                                  modif: _vm.modification
                                },
                                on: { drop: _vm.getModel }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "mega-card",
                  {
                    attrs: {
                      title: _vm.$t("model_rec_imgs"),
                      menu: [{ icon: "i-plus", click: _vm.addImage }]
                    }
                  },
                  [
                    _vm.serverRefImages.length
                      ? _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("model_rec_server")))
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "menu-list border-left" },
                            _vm._l(_vm.serverRefImages, function(img, i) {
                              return _c(
                                "li",
                                {
                                  key: i,
                                  staticClass: "menu-item cursor-pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.editImage(img["idt_ref_image"])
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "form-label" },
                                        [
                                          _vm._v(
                                            "ID: " + _vm._s(img.idt_ref_image)
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "mr-auto form-label" },
                                        [_vm._v(_vm._s(img.name))]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { width: "40px" } },
                                    [
                                      _c("mega-image", {
                                        attrs: {
                                          ratio: "1x1",
                                          small: "",
                                          src: img.url
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.ref_images.length
                      ? _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("model_rec_old")))
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "menu-list border-left" },
                            _vm._l(_vm.ref_images, function(img, i) {
                              return _c(
                                "li",
                                {
                                  key: i,
                                  staticClass: "menu-item cursor-pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.editImage(img["idt_ref_image"])
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "form-label" },
                                        [
                                          _vm._v(
                                            "ID: " + _vm._s(img.idt_ref_image)
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "mr-auto form-label" },
                                        [_vm._v(_vm._s(img.name))]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { width: "40px" } },
                                    [
                                      _c("mega-image", {
                                        attrs: {
                                          ratio: "1x1",
                                          small: "",
                                          src: img.url
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("new-image", {
        ref: "new-image",
        attrs: { id: _vm.model_id, mod_id: _vm.modification_id },
        on: { close: _vm.getModel }
      }),
      _vm._v(" "),
      _c("edit-image", { ref: "edit-image", on: { close: _vm.getModel } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }